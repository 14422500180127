import React from 'react';

import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import './TestsPopupPage.scss';

const TestsPopupPage = () => (
  <div className=".tests-popup-page">
    <div>
      <img src="/images/norma-rozpraszanie-pochlanianie.jpg" alt="" className="img-responsive" />
    </div>
    <h2>
      <FormattedMessage id="testsPopupPageTitle" />
    </h2>
    <p className="paragraph description-text">
      <FormattedHTMLMessage id="testsPopupPageDescPart1" />
    </p>
    <div>
      <img src="/images/norma-wskazniki.jpg" alt="" className="img-responsive" />
    </div>
    <p className="paragraph description-text">
      <FormattedHTMLMessage id="testsPopupPageDescPart2" />
    </p>
    <div>
      <img src="/images/norma-wykresy.jpg" alt="" className="img-responsive" />
    </div>
    <p className="paragraph description-text">
      <FormattedHTMLMessage id="testsPopupPageDescPart3" />
    </p>
    <div>
      <img src="/images/norma-top.jpg" alt="" className="img-responsive" />
    </div>
  </div>
);

export default TestsPopupPage;
