import React from 'react';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';

import CloseModal from 'components/CloseModal';
import TestsPopupPage from 'components/TestsPopupPage';

const TestsPopup = () => (
  <ModalRoutingContext.Consumer>
    {({ closeTo }) => (
      <>
        <div>
          <CloseModal close={closeTo} />
        </div>
        <TestsPopupPage />
      </>
    )}
  </ModalRoutingContext.Consumer>
);

export default TestsPopup;
